<template>
  <div class="container">
    <PublicHeader :totalName="$t('Inpour').title" />

    <div class="pay-type">
      <div
        class="pay"
        :class="{ active: activePayType === 0 }"
        @click="activePayType = 0"
      >
        <img src="@/assets/bank.png" alt="" />
        <span>{{ $t('Inpour').Bank }}</span>
      </div>
      <div
        class="pay"
        v-if="basicConfig.IsShowCurrency"
        :class="{ active: activePayType === 1 }"
        @click="activePayType = 1"
      >
        <img src="@/assets/bit.png" alt="" />
        <span>{{ $t('Inpour').digitalCurrency }}</span>
      </div>
    </div>

    <div class="info" v-if="basicConfig.BankEnable && activePayType === 0">
      <van-field
        v-model="basicConfig.SystemBankName"
        disabled
        :label="$t('rechargeService').OpenBank"
      />
      <van-field
        v-model="basicConfig.CardNo"
        disabled
        :label="$t('bank').card"
        id="textarea"
      >
      </van-field>
      <van-field
        v-model="basicConfig.CardUserName"
        disabled
        :label="$t('rechargeService').CardUserName"
      />
      <i></i>
    </div>

    <p>{{ $t('Inpour').SelectAmount }}</p>
    <div class="money-list">
      <span
        v-for="item in list"
        :style="{ backgroundImage: 'url(' + item.bg + ')' }"
        :key="item.money"
        @click="form.Amount = item.money"
        >{{ item.money }}</span
      >
    </div>

    <van-field
      class="input"
      :border="false"
      v-model="form.Amount"
      :label="$t('Inpour').Amount"
      :placeholder="placeholder"
    />
    <div class="bigBtn" @click="handleSubmit">
      {{ $t('Inpour').Submit }}
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import transactionApi from '@/api/transaction'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      form: {
        IsUpper: true,
        Amount: null,
      },
      activePayType: 0,
      placeholder: '',
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
    list() {
      let arr = []
      if (this.basicConfig.RechargeAmounts) {
        let a = this.basicConfig.RechargeAmounts.split(',')
        a.forEach((item, index) => {
          if (index > 4) return
          let num = index + 1
          arr.push({
            money: item,
            bg: require(`@/assets/btn_chip_${num}.png`),
          })
        })
      }

      return arr
    },
  },
  methods: {
    async handleSubmit() {
      let form = { ...this.form }
      if (form.Amount === '') {
        this.$toast({
          message: this.$t('Withdrawal').AmountMessage,
          position: 'bottom',
        })
        return
      }
      form.Amount = Number(form.Amount)
      await transactionApi.TransferRecords(form)
      this.$toast.success(this.$t('public').SubmittedSuccessfully)
      if (this.basicConfig.IsRechargeToService) {
        this.$router.push({
          name: 'RechargeService',
          params: { Amount: form.Amount },
        })
      } else {
        this.$router.push({
          name: 'AccessLog',
        })
      }
    },
    async getUserInfo() {
      let res = await userApi.userInfo()
      this.placeholder =
        this.basicConfig.Symbol +
        res.RechargeLowLimit +
        ' ~ ' +
        this.basicConfig.Symbol +
        (res.RechargeTopLimit ? res.RechargeTopLimit : '')
    },
  },
  created() {
    this.getUserInfo()
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size 12px
  color #ebe5cd
  overflow-x hidden
  height 100vh
  padding 48.5px 10px 0
  .pay-type
    display flex
  .active
    background: linear-gradient(315deg,#e8d49e,#d6b372)
    border: 1Px solid #f8c653
    color #303030 !important
  .pay
    color #d0c9c9
    width 68px
    height 68px
    margin 10px 6px
    border-radius 5px
    flex-column()
    text-align: center;
    img
      width 33px
      height 33px
      margin-bottom 5px
  p
    font-size: 15px;
    color: #ebe5cd;
    margin 15px 0 25px
  .money-list
    height 55px
    display flex
    justify-content center
    span
      width 54px
      height 100%
      margin 5px
      flex-column()
      background-size 100% 100%
  .input
    color: #fff;
    border-bottom 1Px solid  #40404b
    border-top 1Px solid  #40404b
    background none
    margin-top 28px
    >>> .van-field__control
      color #FFF
    >>> .van-field__label
      color #ebe5cd
    >>> input::-webkit-input-placeholder
      color #969799
  .bigBtn
    width: 100%
    height 40px
    flex-center()
    color #222222
    font-size: 15px
    background: linear-gradient(315deg,#e8d49e,#d6b372)!important;
    margin 25px auto
    border-radius: 5px
  .info
    .van-field
      margin 0
      background none
      >>> .van-field__label
        color #ebe5cd
      >>> .van-field__control
        color #fff
      >>> input::-webkit-input-placeholder
        color #969799
</style>
