import { fetch, post } from '@/utils/axios'

export default {
  TransferRecords: (params) => {
    return post('/app/TransferRecords', params)
  },
  getRecords: (params) => {
    return fetch('/app/TransferRecords', params)
  },
  amounts: (params) => {
    return fetch('/app/Users/amounts', params)
  },
  orderList: (params) => {
    return fetch('/app/Order', params)
  },
  userInfo: () => {
    return fetch('/app/Users')
  },
  charge: (data) => {
    return post('/app/TransferRecords/charge', data)
  },
}
